import { useFetchApiList } from '@stagapps/redux-utils';
import { Button, Form } from 'antd';
import companyApiCall from 'apps/investment/apiCalls/company/list';
import employeeApiCall from 'apps/investment/apiCalls/employee/list';
import FormattedNumberInput from 'common/form/FormattedNumberInput';
import DebounceSelect from 'common/ui/DebouceSelect';
import DatePicker from 'common/ui/UIDatePicker';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const dateFields = ['start_date', 'end_date'];

const EmployeeProgramForm = ({
  form,
  submiting,
  onFinish,
  initialValues = {},
  name,
}) => {
  const {
    data: companies = [],
    load: fetchCompanyList,
    isLoading: isLoadingCompanies,
  } = useFetchApiList(companyApiCall, { resourceName: 'data' });

  const {
    data: employees = [],
    load: fetchEmployeeList,
    isLoading: isLoadingEmployees,
  } = useFetchApiList(employeeApiCall, { resourceName: 'data' });

  useEffect(() => {
    if (initialValues.company_id) {
      fetchCompanyList({ id: initialValues.company_id });
    }
    if (initialValues.employee_id) {
      fetchEmployeeList({ id: initialValues.employee_id });
    }
  }, [initialValues]);

  useEffect(() => {
    form.setFieldsValue(ensureDateFields(initialValues, dateFields));
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={values => onFinish(convertDateFormData(values, dateFields))}
      name={name || 'stock_form'}
      scrollToFirstError
    >
      <Form.Item name="company_id" label="Công ty">
        <DebounceSelect
          placeholder="Chọn công ty"
          fetchOptions={fetchCompanyList}
          fetching={isLoadingCompanies}
          options={companies}
          displayFn={o => {
            return {
              value: o.id,
              label: o.name,
            };
          }}
        />
      </Form.Item>
      <Form.Item name="employee_id" label="Nhân viên">
        <DebounceSelect
          placeholder="Chọn nhân viên"
          fetchOptions={fetchEmployeeList}
          fetching={isLoadingEmployees}
          options={employees}
          displayFn={o => {
            return {
              value: o.id,
              label: o.name ? `${o.name} - ${o.email}` : o.email,
            };
          }}
        />
      </Form.Item>
      <Form.Item name="start_date" label="Ngày bắt đầu">
        <DatePicker />
      </Form.Item>
      <Form.Item name="end_date" label="Ngày kết thúc">
        <DatePicker />
      </Form.Item>
      <Form.Item name="employee_contribute_amount" label="Nhân viên đóng góp">
        <FormattedNumberInput />
      </Form.Item>
      <Form.Item name="company_contribute_amount" label="Công ty đóng góp">
        <FormattedNumberInput />
      </Form.Item>
      <Form.Item name="investment_interval" label="Số kỳ">
        <FormattedNumberInput />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EmployeeProgramForm;
